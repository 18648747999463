import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import { rhythm, scale } from '../utils/typography'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  render() {
    const siteTitle = 'nicolodavis.com'
    const { frontmatter } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            { name: 'description', content: frontmatter.excerpt },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: 'nicolodavis.com' },
            { name: 'twitter:title', content: frontmatter.title },
          ]}
          title={`${frontmatter.title} | ${siteTitle}`}
        />
        <h1>{frontmatter.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: 'block',
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {frontmatter.date}
        </p>
        {this.props.children}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />
      </Layout>
    )
  }
}

export default BlogPostTemplate
