import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`A new release (v0.33) of `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/"}}>{`boardgame.io`}</MDXTag>{` is finally out!
The significant addition in this release is the ability to subdivide turns
into `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/documentation/#/stages"}}>{`Stages`}</MDXTag>{`. What’s more,
different players can be in different stages during a turn.  Each stage
can define its own set of moves.`}</MDXTag>
<MDXTag name="p" components={components}>{`In previous releases we only had the concept of `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/documentation/#/phases"}}>{`Phases`}</MDXTag>{`.
This has served us well, but it was also a frequent source of confusion:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Are turns inside phases or are phases inside turns?`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The answer:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Both!`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`didn’t particularly alleviate the confusion. It was true that a phase was
a completely orthogonal concept to a turn, and the game could switch
between phases at any time, but this frequently caused awkward interactions
with turn orders and other turn related things.`}</MDXTag>
<MDXTag name="p" components={components}>{`This prompted the creation of a new concept (Stages) to represent
`}<MDXTag name="em" components={components} parentName="p">{`phases inside turns`}</MDXTag>{` and the old concept (Phases) has now been relegated
to being a `}<MDXTag name="em" components={components} parentName="p">{`game phase`}</MDXTag>{` inside which turns are played.`}</MDXTag>
<MDXTag name="p" components={components}>{`Along the way many other API details were refined, leading to a near rewrite
of everything. The new API is more streamlined and intuitive in many ways.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Migration Guide`}</MDXTag>
<MDXTag name="h3" components={components}>{`The Game() constructor is gone.`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token keyword">import</span> <span className="token punctuation">{"{"}</span> Game <span className="token punctuation">{"}"}</span> <span className="token keyword">from</span> <span className="token string">'boardgame.io/core'</span><span className="token punctuation">;</span>{"\n"}{"\n"}<span className="token keyword">const</span> game <span className="token operator">=</span> <span className="token function">Game</span><span className="token punctuation">(</span><span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span><span className="token punctuation">)</span><span className="token punctuation">;</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`This was never really necessary and has been trimmed away.
Now you can just do:`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token keyword">const</span> game <span className="token operator">=</span> <span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span><span className="token punctuation">;</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`The flow section is gone.`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"\n"}{"  "}flow<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`You can now just define things like `}<MDXTag name="strong" components={components} parentName="p">{`phases`}</MDXTag>{` in the top-level:`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"  "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> <span className="token operator">...</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`You can define moves inside phases!`}</MDXTag>
<MDXTag name="p" components={components}>{`The previous mechanism of restricting moves in a phase involved
specifying `}<MDXTag name="strong" components={components} parentName="p">{`allowedMoves`}</MDXTag>{`.`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> moveA<span className="token punctuation">,</span> moveB<span className="token punctuation">,</span> moveC <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"\n"}{"  "}flow<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"      "}<span className="token constant">A</span><span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"        "}allowedMoves<span className="token punctuation">:</span> <span className="token punctuation">[</span><span className="token string">'moveA'</span><span className="token punctuation">]</span><span className="token punctuation">,</span>{"\n"}{"      "}<span className="token punctuation">{"}"}</span>{"\n"}{"    "}<span className="token punctuation">{"}"}</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`moveA / moveB / moveC are functions whose definitions are not shown here`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Now you can just specify the moves that will be used in a phase
directly in the phase’s definition:`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> moveB<span className="token punctuation">,</span> moveC <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"\n"}{"  "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}<span className="token constant">A</span><span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"      "}moves<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> moveA <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"    "}<span className="token punctuation">{"}"}</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="p" components={components}>{`If a phase does not contain a `}<MDXTag name="strong" components={components} parentName="p">{`moves`}</MDXTag>{` then the global `}<MDXTag name="strong" components={components} parentName="p">{`moves`}</MDXTag>{` section
will be used.`}</MDXTag>
<MDXTag name="h3" components={components}>{`onBegin / onEnd / endIf`}</MDXTag>
<MDXTag name="p" components={components}>{`The following hooks and triggers have been renamed:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`onPhaseBegin —> onBegin`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`onTurnBegin —> onBegin`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`onPhaseEnd —> onEnd`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`onTurnEnd —> onEnd`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`endPhaseIf —> endIf`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`endTurnIf —> endIf`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`endGameIf —> endIf`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Wait, how come some of them are now the same?`}</MDXTag>
<MDXTag name="p" components={components}>{`That’s because each one goes into a different section in the game object,
so there is no longer any need to differentiate them by using a different name.`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}endIf<span className="token punctuation">:</span>{"  "}<span className="token comment">// endGameIf</span>{"\n"}{"\n"}{"  "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}onBegin<span className="token punctuation">:</span>{"\n"}{"    "}onEnd<span className="token punctuation">:</span>{"\n"}{"    "}endIf<span className="token punctuation">:</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"\n"}{"  "}turn<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}onBegin<span className="token punctuation">:</span>{"\n"}{"    "}onEnd<span className="token punctuation">:</span>{"\n"}{"    "}endIf<span className="token punctuation">:</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`A new turn section.`}</MDXTag>
<MDXTag name="p" components={components}>{`You saw this in the previous example already.  This section contains everything
that is turn related (including the Turn Order):`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}turn<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}order<span className="token punctuation">:</span> TurnOrder<span className="token punctuation">.</span><span className="token constant">DEFAULT</span><span className="token punctuation">,</span>{"  "}<span className="token comment">// previously turnOrder</span>{"\n"}{"    "}moveLimit<span className="token punctuation">:</span> <span className="token number">1</span><span className="token punctuation">,</span>{"  "}<span className="token comment">// previously movesPerTurn</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`Phase semantics.`}</MDXTag>
<MDXTag name="p" components={components}>{`Phases are now game phases inside which turns are played. When a phase ends, it
first ends the turn. There are also no “default” phase anymore. The game is
either in a phase or not (in which case `}<MDXTag name="strong" components={components} parentName="p">{`ctx.phase`}</MDXTag>{` will contain `}<MDXTag name="strong" components={components} parentName="p">{`null`}</MDXTag>{`).`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`startingPhase`}</MDXTag>{` is also now deprecated in favor of a `}<MDXTag name="strong" components={components} parentName="p">{`start`}</MDXTag>{` option inside
the phase that you want the game to begin in (if you want the game to begin in
a phase; this is optional):`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}phases<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}<span className="token constant">A</span><span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span> start<span className="token punctuation">:</span> <span className="token boolean">true</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"    "}<span className="token constant">B</span><span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span><span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"    "}<span className="token constant">C</span><span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span><span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span><span className="token punctuation">,</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`Disabling events.`}</MDXTag>
<MDXTag name="p" components={components}>{`First, all players can call events (unlike the previous releases where only
the `}<MDXTag name="strong" components={components} parentName="p">{`currentPlayer`}</MDXTag>{` could). Also, all events are enabled by default.`}</MDXTag>
<MDXTag name="p" components={components}>{`To disable an event from being called from the client, use the `}<MDXTag name="strong" components={components} parentName="p">{`events`}</MDXTag>{` section:`}</MDXTag>
<div className="gatsby-highlight" data-language="js"><pre style={{counterReset: 'linenumber NaN'}} className="language-js line-numbers"><code className="language-js"><span className="token punctuation">{"{"}</span>{"\n"}{"  "}events<span className="token punctuation">:</span> <span className="token punctuation">{"{"}</span>{"\n"}{"    "}endTurn<span className="token punctuation">:</span> <span className="token boolean">false</span><span className="token punctuation">,</span>{"\n"}{"  "}<span className="token punctuation">{"}"}</span>{"\n"}<span className="token punctuation">{"}"}</span></code><span aria-hidden="true" className="line-numbers-rows" style={{whiteSpace: 'normal', width: 'auto', left: 0}}><span /><span /><span /><span /><span /></span></pre></div>

<MDXTag name="h3" components={components}>{`setActionPlayers is gone`}</MDXTag>
<MDXTag name="p" components={components}>{`It is replaced by `}<MDXTag name="strong" components={components} parentName="p">{`setActivePlayers`}</MDXTag>{`.  See `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgame.io/documentation/#/stages?id=advanced"}}>{`here`}</MDXTag>{` for more details.`}</MDXTag>
<MDXTag name="h3" components={components}>{`The turn begins at 1 (and not 0).`}</MDXTag>
<MDXTag name="p" components={components}>{`This probably doesn’t matter too much, but just so you know.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  